import { FunctionalComponent, h } from "preact";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { strings } from "../constants/strings";
import { logEvent } from "../logging/eventProducer";
import { useEffect, useState } from "preact/hooks";

const ThankYou: FunctionalComponent = () => {
  const [hasSentMetric, setHasSentMetric] = useState(false);

  useEffect(() => {
    if (!hasSentMetric) {
      const params: URLSearchParams = new URLSearchParams(document.location.search);
      const cid: string | null = params.get("cid");
      const pid: string | null = params.get("pid");
      const method: string | null = params.get("method");

      if (cid && pid && method) {
        logEvent(`BUY_COMPLETE_c_${cid}_p_${pid}_${method}`);
      } else {
        logEvent("BUY_COMPLETE");
      }

      setHasSentMetric(true);
    }
  }, [hasSentMetric]);

  return (
    <div style={styles.parentWrapper}>
      <div style={styles.closeIconWrapper}>
        <IconButton
          color="default"
          aria-label="back"
          size={"small"}
          // @ts-ignore
          style={styles.closeIcon}
          onClick={() => {
            // @ts-ignore
            if (typeof Android !== "undefined" && Android !== null) {
              // @ts-ignore
              Android.closeAd();
            } else {
              logEvent("TEMPO_CLOSE_AD");
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ marginTop: "3em", width: "auto" }}>
        <CheckCircleOutlineIcon color={"success"} fontSize={"large"} sx={{ fontSize: "4em" }} />
      </div>
      <h2 style={{ fontSize: "2em" }}>{strings.orderConfirmed}</h2>
      <span style={{ fontSize: "1.3em", textAlign: "center", marginTop: "1em" }}>
        {strings.paymentThank}
      </span>
      <span style={{ fontSize: "1em", marginTop: "3em" }}>{strings.windowClose}</span>
    </div>
  );
};

const styles = {
  parentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "50%",
    width: "100%",
    padding: "2em",
    position: "relative",
  },
  closeIconWrapper: {
    position: "absolute",
    right: "0",
    top: "3px",
    backgroundColor: "rgba(255,255,255,0.5)",
    borderRadius: "50%",
    zIndex: 2,
  },
  closeIcon: {
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
    filter: "drop-shadow(0px 0px 0px #000000)",
    position: "absolute",
    right: "8px",
    top: "4px",
  },
};

export default ThankYou;
