import { FunctionalComponent, h } from "preact";
import { P, H6, PLarge } from "@tempoplatform/tpds/_dist/elements/typography";
import { Rating } from "@mui/material";
import { simplifyLongNumber } from "../../utils/number";
import { getCapitalizeWord } from "../../utils/string";
import { ProductData } from "../../types/types.ts";

interface Props {
  product: ProductData;
  data: any;
  quantity: any;
  reviewScores: any;
  ad_type: string;
}

const ProductDetails: FunctionalComponent<Props> = props => {
  const { product, quantity, ad_type, reviewScores } = props;
  const hasReviewCount = reviewScores && reviewScores.count;
  const hasScore = reviewScores && reviewScores.score;
  return (
    <div style={styles.productSection} className="mt-1">
      {ad_type !== "brand_awareness" && product.price && (
        <H6 isMedium>${(product.price.times(quantity)).toFixed(2)}</H6>
      )}
      <PLarge isMedium>{getCapitalizeWord(product.name)}</PLarge>
      <div style={styles.ratingDisplay}>
        {hasScore && (
          <Rating defaultValue={reviewScores.score} precision={0.1} size="small" readOnly />
        )}
        {hasReviewCount && (
          <P isMedium style={{ marginLeft: "4px" }}>
            {simplifyLongNumber(98465164)}
          </P>
        )}
      </div>
    </div>
  );
};

const styles = {
  productSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  ratingDisplay: {
    display: "flex",
    transform: "scale(0.8)",
    height: "auto",
    position: "relative",
    top: "-6px",
    width: "auto",
    margin: "8px auto 8px auto",
  },
};

export default ProductDetails;
