import { Fragment, FunctionalComponent, h } from "preact";
import { logEvent } from "../../logging/eventProducer";
import { useEffect, useState } from "preact/hooks";
import { H3, H6, PTiny } from "@tempoplatform/tpds/_dist/elements/typography";
import { TextInput } from "@tempoplatform/tpds/_dist/elements/input";
import { Button } from "@tempoplatform/tpds/_dist/elements/buttons";
import Select from "@tempoplatform/tpds/_dist/components/select";
import Spinner from "@tempoplatform/tpds/_dist/components/spinner";
import { toUploadFormAdTypeData } from "../../api/api";
import { ProductData } from "../../types/types.ts";

interface Props {
  productFlag: any;
  product: ProductData;
  data: any;
  setSelectedProductIndex: (i: number) => void;
  resetVariantSelection: () => void;
  cid: number;
}

enum ControlType {
  SELECT = "SELECT",
  TEXTFIELD = "TEXTFIELD",
}

type PropertyValue = {
  value: string | number;
};

type FormProperty = {
  // db props
  property_name: string; // unique identifier
  property_type: string;
  property_order: number; // order of appearance in form
  property_default: string; // label to display
  property_values: PropertyValue[];
  is_mandatory: boolean;
  // local usage
  entered_value?: number | string; // entered value in case of textfield
  selected_index?: number; // selected value in case of select
  form_error?: string; // form error to display
  control_type?: ControlType; // control type to display (select / textfield)
};

const prepareFormPropertiesData = (data: any, form_properties: FormProperty[], product: ProductData) => {
  // sort form properties by property_order
  const sortedFormProperties: FormProperty[] = form_properties.sort(
    (a: FormProperty, b: FormProperty) => a.property_order - b.property_order,
  );
  // set form properties control type
  const preparedFormProperties: FormProperty[] = sortedFormProperties.map((fp: FormProperty) => {
    // if property_name is "model", inject the actual product options if there are none
    if (fp.property_name === "model" && fp.property_values.length === 0) {
      fp.property_values = data.campaign_products.map((campaign_product: any, index: number) => {
        return {
          value: campaign_product.product.name,
          label: index.toString(),
        };
      });
    }
    // if property_name is "plan_to_purchase", inject some options if there are none
    if (fp.property_name === "plan_to_purchase" && fp.property_values.length === 0) {
      fp.property_values = [{ value: "Option 1" }, { value: "Option 2" }];
    }
    // if property_name is "model", set selected index equal to product we are viewing
    if (fp.property_name === "model") {
      data.campaign_products.forEach((campaign_product: any, index: number) => {
        if (product.name === campaign_product.product.name) {
          fp.selected_index = index;
        }
      });
    }
    // set control type
    const hasValues = fp.property_values && fp.property_values.length > 0;
    fp.control_type = hasValues ? ControlType.SELECT : ControlType.TEXTFIELD;
    if (fp.control_type === ControlType.SELECT && !fp.selected_index && fp.selected_index !== 0) {
      fp.selected_index = 0;
    }
    return fp;
  });
  return preparedFormProperties;
};

const prepareSelectFormFieldValues = (array: PropertyValue[]) => {
  const valueOptions = array.map((propertyValue, index) => {
    return {
      value: index.toString(),
      label: propertyValue.value,
    };
  });

  return valueOptions;
};

const ImagesAndFormAdType: FunctionalComponent<Props> = props => {
  const { product, productFlag, data, setSelectedProductIndex, resetVariantSelection, cid } = props;
  const [formProperties, setFormProperties] = useState<FormProperty[]>(
    prepareFormPropertiesData(data, data.form_properties, product),
  );
  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);
  const [submitSuccessful, setSubmitSuccessful] = useState<boolean>(false);
  const [submitFailed, setSubmitFailed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    prepareFormPropertiesData(data, data.form_properties, product);
  }, [data, product, submittedOnce]);

  const formIsValid = () => {
    let passed = true;
    formProperties.forEach((fp: FormProperty) => {
      if (fp.is_mandatory && fp.control_type === ControlType.TEXTFIELD) {
        if (!fp.entered_value || String(fp.entered_value).length < 2) {
          passed = false;
        }
      }
    });

    return passed;
  };

  const handleSubmitButtonClick = () => {
    setSubmittedOnce(true);
    // const errorsObject = validateFields();
    // setFormErrorsObject(errorsObject);
    const passed = formIsValid();
    if (passed) {
      logEvent("FORM_SUBMIT_VALID");
      submitFormRequest();
    } else {
      logEvent("FORM_SUBMIT_INVALID");
    }
  };

  const handleCloseButtonClick = () => {
    logEvent("TEMPO_CLOSE_AD");
  };

  const handleReturnToFormButtonClick = () => {
    setSubmitFailed(false);
  };

  const isInvalidEnteredValue = (value: string | number) => {
    if (!value) return true;
    if (value === "") return true;
    if (String(value).length < 2) return true;
  };

  const submitFormRequest = async () => {
    const requestBody = {};
    formProperties.forEach((fp: FormProperty) => {
      if (fp.control_type === ControlType.SELECT) {
        requestBody[fp.property_name] = fp.property_values[fp.selected_index].value;
      } else {
        requestBody[fp.property_name] = fp.entered_value;
      }
    });

    setLoading(true);

    const response = await toUploadFormAdTypeData(requestBody, cid);
    if (response.status === 200) {
      logEvent("FORM_SUBMIT_SUCCESS");
      setSubmitSuccessful(true);
      setLoading(false);
    } else {
      logEvent("FORM_SUBMIT_FAILURE");
      setSubmitFailed(true);
      setLoading(false);
    }
  };

  const resetFields = () => {
    const resetFormProperties: FormProperty[] = formProperties.map((fp: FormProperty) => {
      fp.selected_index = 0;
      fp.entered_value = "";
      fp.form_error = "";
      return fp;
    });
    setFormProperties(resetFormProperties);
    setSubmittedOnce(false);
    setSubmitSuccessful(false);
    setSubmitFailed(false);
    setLoading(false);
  };

  const updateFieldValue = (name: string, value: string | number) => {
    const updatedFormProperties = formProperties.map((fp: FormProperty) => {
      if (fp.property_name !== name) {
        // no change
        return fp;
      }
      // update 'select' property type
      if (fp.control_type === ControlType.SELECT) {
        fp.selected_index = value as number;
        return fp;
      }
      // update 'textfield' property type
      fp.entered_value = value;
      return fp;
    });
    setFormProperties(updatedFormProperties);
  };

  const passed = formIsValid();

  if (!productFlag && submittedOnce) {
    resetFields();
    return null;
  }

  if (!productFlag) {
    return null;
  }

  if (submitSuccessful) {
    return (
      <div className="px-6 pt-3 flex flex-col gap-y-3 w-full h-full fixed top-0 left-0 z-[99999] bg-white items-center justify-center">
        <div className="w-full mb-3 flex flex-col gap-y-1 items-center">
          <br />
          <H3 isBold>Thank you</H3>
          <H6 isMedium>for registering your interest.</H6>
          <div
            className="flex justify-center"
            style={{ animation: "custompulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite" }}
          >
            <H6 isMedium>We will be in touch soon!</H6>
          </div>
          <br />
          <Button
            isLarge
            className="w-full rounded-[10px] max-w-[200px]"
            variant="info"
            style={{ borderRadius: "6px", fontWeight: "bold !important" }}
            onClick={handleCloseButtonClick}
          >
            Close window
          </Button>
        </div>
      </div>
    );
  }

  if (submitFailed) {
    return (
      <div className="px-6 pt-3 flex flex-col gap-y-3 items-center">
        <div className="w-full mb-3 flex flex-col gap-y-1 items-center">
          <br />
          <H3 isBold>Oops 😬</H3>
          <H6 isMedium className="text-center">
            An error occurred while submitting the form.
          </H6>
          <H6 isMedium className="text-center">
            We apologize for the inconvenience! Please try again later.
          </H6>
          <br />
          <Button
            isLarge
            className="w-full rounded-[10px] max-w-[200px]"
            variant="info"
            style={{ borderRadius: "6px", fontWeight: "bold !important" }}
            onClick={handleReturnToFormButtonClick}
          >
            Return to form
          </Button>
        </div>
      </div>
    );
  }

  // console.log("data", data);
  // console.log("product", product);
  // console.log("product.name", product.name);
  // console.log("productFlag", productFlag);
  // console.log("formProperties", formProperties);

  return (
    <Fragment>
      <div className="px-6 pt-3 flex flex-col gap-y-3 items-center">
        {!loading && (
          <div className="w-full mb-3 mt-1 flex flex-col gap-y-1 items-center">
            <H6 isBold>{product.name}</H6>
            <H6 isMedium>Register your interest below</H6>
          </div>
        )}
        {loading && (
          <div className="flex flex-col gap-y-1 items-center">
            <br />
            <Spinner color="blue" />
            <H6
              isMedium
              style={{ animation: "custompulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite" }}
            >
              Submitting...
            </H6>
          </div>
        )}
        {!loading && (
          <Fragment>
            {formProperties.map((form_property: FormProperty, index: number) => {
              if (form_property.control_type === ControlType.SELECT) {
                return (
                  <div key={index}>
                    <Select
                      handleIndexSelection={(index: number) => {
                        updateFieldValue(form_property.property_name, index);
                        if (form_property.property_name === "model") {
                          setSelectedProductIndex(index);
                          resetVariantSelection();
                        }
                      }}
                      selectedIndex={form_property.selected_index || 0}
                      labelProp="label"
                      idProp="value"
                      options={prepareSelectFormFieldValues(form_property.property_values)}
                      isInvalid={false} // select controls always display a valid option
                    />
                  </div>
                );
              }
              // otherwise we display a textfield
              const isInvalid = isInvalidEnteredValue(form_property.entered_value);
              return (
                <div key={index}>
                  <TextInput
                    className="!bg-transparent !border-[#ededed] active:!border-blue focus:!border-blue border-2"
                    value={form_property.entered_value}
                    isInvalid={submittedOnce && isInvalid}
                    placeholder={form_property.property_default}
                    onChange={e => updateFieldValue(form_property.property_name, e.target.value)}
                  />
                  {submittedOnce && isInvalid && (
                    <PTiny className="text-tertiary">Please fill in the field</PTiny>
                  )}
                </div>
              );
            })}
            {submittedOnce && !passed && (
              <PTiny className="text-red">Please verify the form fields</PTiny>
            )}
            <Button
              isLarge
              className="w-full rounded-[10px]"
              variant="info"
              style={{ borderRadius: "6px", fontWeight: "bold !important" }}
              onClick={handleSubmitButtonClick}
            >
              Submit
            </Button>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ImagesAndFormAdType;
