import { Fragment, FunctionalComponent, h } from "preact";
import { MutableRef, useEffect, useRef, useState } from "preact/hooks";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { toCreatePaymentIntent, toUpdatePaymentIntent } from "../../../api/api";
import { logEvent } from "../../../logging/eventProducer";
import {
  CanMakePaymentResult,
  PaymentRequest,
  PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestShippingAddress,
  PaymentRequestShippingAddressEvent,
  PaymentRequestShippingOption,
  PaymentRequestUpdateOptions,
  Stripe,
  StripeElements,
  StripePaymentRequestButtonElementClickEvent,
} from "@stripe/stripe-js";
import { PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ProductData } from "../../../types/types.ts";
import BigNumber from "bignumber.js";
import { HTML_ADS_DOMAIN } from "../../../constants/strings.tsx";
import { PaymentRequestShippingOptionEvent } from "@stripe/stripe-js/types/stripe-js/payment-request";
import { ShippingAddress } from "../../../utils/stripe.tsx";

interface Props {
  product: ProductData;
  variants: any;
  data: any;
  pid: number;
  cid: number;
  sku: number;
  sellerSku: string;
  quantity: number;
  productVariant: any;
  currentSelection: any;
  stripeConnectedAccountID: string;
}

const buttonContainerStyle = {
  position: "fixed",
  bottom: 0,
  background: "white",
  zIndex: 999,
  left: 0,
  padding: "5px",
};

const ShoppableNonAndroid: FunctionalComponent<Props> = ({
  product,
  cid,
  pid,
  sku,
  sellerSku,
  quantity,
  productVariant,
  currentSelection,
  stripeConnectedAccountID,
}) => {
  const [selectedShippingMode, setSelectedShippingMode] = useState<any>(null);
  const [canMakePayment, setCanMakePayment] = useState<boolean>(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const paymentIntentId: MutableRef<string | null> = useRef<string | null>(null);
  const paymentIntentClientSecret: MutableRef<string | null> = useRef<string | null>(null);

  // Keep the latest details in a ref for the event listeners
  const latestData: MutableRef<{
    product: ProductData;
    quantity: number;
    sku: number;
    cid: number;
    pid: number;
    sellerSku: string;
    productVariant: object;
    // TODO: sort out the types for currentSelection
    currentSelection: any;
    stripeConnectedAccountID: string | null;
  }> = useRef({
    product,
    quantity,
    sku,
    cid,
    pid,
    sellerSku,
    productVariant,
    currentSelection,
    stripeConnectedAccountID,
  });
  latestData.current = {
    product,
    quantity,
    sku,
    cid,
    pid,
    sellerSku,
    productVariant,
    currentSelection,
    stripeConnectedAccountID,
  };

  const shippingOptions: PaymentRequestShippingOption[] = product[
    "campaign_product_shipping_options"
  ]
    ? product["campaign_product_shipping_options"].map(
        (shippingOption: any): PaymentRequestShippingOption => ({
          id: String(shippingOption.shipping_option.id),
          label: shippingOption.shipping_option.display_name,
          detail: `Delivery in ${shippingOption.shipping_option.del_est_min_value} to ${shippingOption.shipping_option.del_est_max_value} ${shippingOption.shipping_option.del_est_max_unit}s`,
          amount: BigNumber(shippingOption.shipping_option.amount)
            .times(100)
            .integerValue()
            .toNumber(),
        }),
      )
    : [
        {
          id: "free",
          label: "Free Shipping",
          detail: "Delivery in 5 to 7 business days",
          amount: 0,
        },
      ];

  // These are both null until the Promises resolve.
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const getVariantInfoAndIds = (): { variant_info_arr: string[]; variant_ids_arr: string[] } => {
    const { productVariant, currentSelection } = latestData.current;

    const variant_info_arr: string[] = [];
    const variant_ids_arr: string[] = [];
    for (const key in productVariant) {
      variant_info_arr.push(`${key}:${productVariant[key]}`);
    }

    for (const id in currentSelection) {
      variant_ids_arr.push(
        `${currentSelection[id].product_variant_id}:${currentSelection[id].product_variant_option_id}`,
      );
    }
    return { variant_info_arr, variant_ids_arr };
  };

  const createRequestBody = (): object => {
    const { variant_info_arr, variant_ids_arr } = getVariantInfoAndIds();
    const { product, quantity, sku, pid, cid, sellerSku, stripeConnectedAccountID } =
      latestData.current;

    const shippingPrice = selectedShippingMode ? selectedShippingMode.amount : 0;

    const requestBody: object = {
      currency: "USD",
      price: product.price.toString(),
      quantity,
      metadata: {
        sku_id: sku,
        product_name: product.name,
        product_id: pid,
        campaign_id: cid,
        seller_sku_id: sellerSku,
        variant_info: variant_info_arr.join(","),
        variant_ids: variant_ids_arr.join(","),
        quantity,
        shipping_option_id: selectedShippingMode ? selectedShippingMode.id : null,
        shipping_option_name: selectedShippingMode ? selectedShippingMode.display_name : null,
        shipping_price: selectedShippingMode
          ? BigNumber(selectedShippingMode.amount).toString()
          : "0",
      },
      shipping_price: BigNumber(shippingPrice).toString(),
    };
    if (stripeConnectedAccountID) {
      requestBody["stripe_account"] = stripeConnectedAccountID;
    }

    return requestBody;
  };

  const createPaymentIntent = async (): Promise<{
    clientSecret: string;
    intentId: string;
  } | null> => {
    const requestBody = createRequestBody();
    try {
      const response = await toCreatePaymentIntent(requestBody);
      const data = await response.json();
      if (data.error) {
        console.error(`Error creating payment intent: ${data.error}`);
        return;
      }

      logEvent("PAYMENT_INTENT_SUCCESS");

      return {
        clientSecret: data.client_secret,
        intentId: data.id,
      };
    } catch (err: any) {
      console.error(`Uncaught error in createPaymentIntent(): ${err}`);
      return null;
    }
  };

  const updatePaymentIntent = async (
    intentId: string,
    requestBody: object,
  ): Promise<string | null> => {
    try {
      const response = await toUpdatePaymentIntent(requestBody, intentId);
      const data = await response.json();
      if (data.error) {
        console.error(`Error updating payment intent: ${data.error}`);
        return;
      }

      logEvent("PAYMENT_INTENT_UPDATE_SUCCESS");

      return data.client_secret;
    } catch (err: any) {
      console.error(`Uncaught error in updatePaymentIntent(): ${err}`);
      return null;
    }
  };

  const updatePaymentIntentShippingAddress = async (
    intentId: string,
    shippingAddress: PaymentRequestShippingAddress,
  ): Promise<string | null> => {
    const { stripeConnectedAccountID } = latestData.current;

    let line1 = "";
    let line2 = "";
    if (shippingAddress.addressLine && shippingAddress.addressLine.length > 0) {
      line1 = shippingAddress.addressLine[0];
      for (let i = 1; i < shippingAddress.addressLine.length; i++) {
        line2 += `${shippingAddress.addressLine[i]} `;
      }
    }
    const reformattedShippingAddress: ShippingAddress = {
      address: {
        city: shippingAddress.city ? shippingAddress.city : "",
        country: shippingAddress.country ? shippingAddress.country : "",
        line1,
        line2,
        postal_code: shippingAddress.postalCode ? shippingAddress.postalCode : "",
        state: shippingAddress.region ? shippingAddress.region : "",
      },
      name: shippingAddress.recipient ? shippingAddress.recipient : "",
    };
    const requestBody: object = {
      shipping: reformattedShippingAddress,
    };
    if (stripeConnectedAccountID) {
      requestBody["stripe_account"] = stripeConnectedAccountID;
    }
    return await updatePaymentIntent(intentId, requestBody);
  };

  const updatePaymentIntentShippingOption = async (
    intentId: string,
    shippingOption: PaymentRequestShippingOption,
  ): Promise<string | null> => {
    const { variant_info_arr, variant_ids_arr } = getVariantInfoAndIds();
    const { product, quantity, sku, pid, cid, sellerSku, stripeConnectedAccountID } =
      latestData.current;

    const requestBody: object = {
      metadata: {
        sku_id: sku,
        product_name: product.name,
        product_id: pid,
        campaign_id: cid,
        seller_sku_id: sellerSku,
        variant_info: variant_info_arr.join(","),
        variant_ids: variant_ids_arr.join(","),
        quantity,
        shipping_option_id: shippingOption.id,
        shipping_option_name: shippingOption.label,
        shipping_price: BigNumber(shippingOption.amount).toString(),
      },
    };
    if (stripeConnectedAccountID) {
      requestBody["stripe_account"] = stripeConnectedAccountID;
    }
    return await updatePaymentIntent(intentId, requestBody);
  };

  const handleShippingAddressChange = async (ev: PaymentRequestShippingAddressEvent) => {
    if (ev.shippingAddress.country !== "US") {
      ev.updateWith({ status: "invalid_shipping_address" });
    } else {
      ev.updateWith({
        status: "success",
      });
    }
  };

  const handleShippingOptionChange = async (ev: PaymentRequestShippingOptionEvent) => {
    // always read from the latest ref, not from stale closures
    const { product, quantity } = latestData.current;

    const newShippingOption: PaymentRequestShippingOption = ev.shippingOption;
    const productPrice: BigNumber = product.price.times(quantity).times(100).integerValue();
    const shippingPrice: BigNumber = BigNumber(newShippingOption.amount);
    const totalPrice: BigNumber = productPrice.plus(shippingPrice);

    ev.updateWith({
      status: "success",
      total: {
        label: product.name,
        amount: totalPrice.toNumber(),
      },
    });
  };

  const createPaymentRequest = async (): Promise<PaymentRequest> => {
    const shippingPrice = selectedShippingMode ? selectedShippingMode.amount : 0;

    const productAmountInteger: number = product.price
      .times(quantity)
      .times(100)
      .integerValue()
      .toNumber();
    const shippingAmountInteger: number = BigNumber(shippingPrice)
      .times(100)
      .integerValue()
      .toNumber();
    const totalAmountInteger: number = productAmountInteger + shippingAmountInteger;

    const paymentRequestData: PaymentRequestOptions = {
      country: "US",
      currency: "usd",
      total: {
        label: product.name,
        amount: totalAmountInteger,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestShipping: true,
      displayItems: [
        {
          label: `${quantity} ${product.name}`,
          amount: productAmountInteger,
        },
      ],
      shippingOptions,
    };
    const newPaymentRequest: PaymentRequest = stripe.paymentRequest(paymentRequestData);

    // Attach event listeners to the PaymentRequest
    newPaymentRequest.on("shippingaddresschange", handleShippingAddressChange);
    newPaymentRequest.on("shippingoptionchange", handleShippingOptionChange);
    newPaymentRequest.on("paymentmethod", handlePaymentMethod);

    setPaymentRequest(newPaymentRequest);

    const result: CanMakePaymentResult = await newPaymentRequest.canMakePayment();
    setCanMakePayment(!!result);
    if (!result) {
      window.alert("Error initializing payment form");
    }

    return newPaymentRequest;
  };

  // TODO: refactor to reduce duplicate code in createPaymentRequest() and updatePaymentRequest()
  const updatePaymentRequest = async (): Promise<PaymentRequest> => {
    const shippingPrice: number = selectedShippingMode ? selectedShippingMode.amount : 0;

    const productAmountInteger: number = product.price
      .times(quantity)
      .times(100)
      .integerValue()
      .toNumber();
    const shippingAmountInteger: number = BigNumber(shippingPrice)
      .times(100)
      .integerValue()
      .toNumber();
    const totalAmountInteger: number = productAmountInteger + shippingAmountInteger;

    const paymentRequestUpdate: PaymentRequestUpdateOptions = {
      total: {
        label: product.name,
        amount: totalAmountInteger,
      },
      displayItems: [
        {
          label: `${quantity} ${product.name}`,
          amount: productAmountInteger,
        },
      ],
      shippingOptions,
    };
    paymentRequest.update(paymentRequestUpdate);
    return paymentRequest;
  };

  const handlePaymentMethod = async (ev: PaymentRequestPaymentMethodEvent) => {
    // https://docs.stripe.com/stripe-js/elements/payment-request-button?client=react#complete-payment
    if (!paymentIntentClientSecret.current) {
      console.error("Error: paymentIntentClientSecret.current is null");
      ev.complete("fail");
    }

    if (ev.shippingOption) {
      await updatePaymentIntentShippingOption(paymentIntentId.current, ev.shippingOption);
    }

    if (ev.shippingAddress) {
      await updatePaymentIntentShippingAddress(paymentIntentId.current, ev.shippingAddress);
    }

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      paymentIntentClientSecret.current,
      {
        payment_method: ev.paymentMethod.id,
        // return_url: `${HTML_ADS_DOMAIN}/thank-you?cid=${cid}&pid=${pid}&method=ios`,
      },
      { handleActions: false },
    );

    const thanksRoute = `/thank-you?cid=${cid}&pid=${pid}&method=ios`;

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      logEvent("APPLE_PAY_PAYMENT_FAILED");
      ev.complete("fail");
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
      // handle the flow.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(paymentIntentClientSecret.current);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          logEvent("APPLE_PAY_PAYMENT_FAILED");
          window.alert("Payment failed. Try again.");
        } else {
          // The payment has succeeded -- show a success message to your customer.
          window.location.href = HTML_ADS_DOMAIN + thanksRoute;
        }
      } else {
        // The payment has succeeded -- show a success message to your customer.
        window.location.href = HTML_ADS_DOMAIN + thanksRoute;
      }
    }
  };

  const onPaymentRequestButtonClick = async (
    event: StripePaymentRequestButtonElementClickEvent,
  ) => {
    logEvent(`APPLE_PAY_CLICKED_PRODUCT_${pid}`);

    // This creates the paymentIntent in the Apple Pay flow
    const { clientSecret, intentId } = await createPaymentIntent();
    if (!clientSecret || !intentId) {
      window.alert("Error creating payment intent");
      return;
    }
    paymentIntentClientSecret.current = clientSecret;
    paymentIntentId.current = intentId;
  };

  useEffect(() => {
    const pShippingOptions = product["campaign_product_shipping_options"];
    if (pShippingOptions && pShippingOptions.length > 0) {
      setSelectedShippingMode(pShippingOptions[0].shipping_option);
    }
  }, [product]);

  useEffect(() => {
    if (!stripe || !elements) return;

    if (!paymentRequest) {
      createPaymentRequest(); // This sets paymentRequest state
    }
  }, [stripe, elements, paymentRequest]);

  useEffect(() => {
    if (!stripe || !elements || !paymentRequest) {
      return;
    }

    updatePaymentRequest();
  }, [stripe, elements, paymentRequest, product, sku, quantity, selectedShippingMode]);

  // TODO: consider adding a loading spinner here
  if (!stripe || !elements) {
    return null;
  }

  return (
    <Fragment>
      <div className="w-full border-t border-[#F1F1F1] p-[16px]">
        <div className="flex flex-col gap-y-2 w-full">
          <div style={buttonContainerStyle}>
            {canMakePayment && paymentRequest && (
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                }}
                onClick={onPaymentRequestButtonClick}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShoppableNonAndroid;
