import { Fragment, FunctionalComponent, h } from "preact";
import { PNano, H2, H5, H6 } from "@tempoplatform/tpds/_dist/elements/typography";

interface ComponentProps {
  reviewScores: any;
}

const ReviewsSummary: FunctionalComponent<ComponentProps> = props => {
  const { reviewScores } = props;

  const percentRecommended = reviewScores.percent_recommended;
  const reviewOverallScore = reviewScores.score;

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <div style={{ width: "calc(100% - 100px)" }}>
          <RowComponent position={5} percentage={reviewScores.bar_5} />
          <RowComponent position={4} percentage={reviewScores.bar_4} />
          <RowComponent position={3} percentage={reviewScores.bar_3} />
          <RowComponent position={2} percentage={reviewScores.bar_2} />
          <RowComponent position={1} percentage={reviewScores.bar_1} addSpace={false} />
        </div>
        <div className="w-[100px] flex justify-center">
          <div style={{ width: "auto" }}>
            <div className="flex items-baseline relative top-[-2px] mb-2">
              <H2 isMedium className="leading-none tracking-tight">
                {reviewOverallScore}
              </H2>
              &thinsp;
              <H6 className="tracking-tight">/5</H6>
            </div>
            {percentRecommended && (
              <Fragment>
                <div className="flex items-baseline relative top-[-2px]">
                  <H2 isMedium className="leading-none">
                    {percentRecommended}
                  </H2>
                  <H5 className="leading-none">&thinsp;%</H5>
                </div>
                <PNano>Recommended</PNano>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface RowProps {
  position: number;
  percentage: number;
  addSpace?: boolean;
}

const RowComponent: FunctionalComponent<RowProps> = props => {
  const { position, percentage, addSpace = true } = props;

  return (
    <Fragment>
      <div className="w-full flex relative items-center justify-between h-[6px] mb-2">
        <PNano isBold style={{ width: "8px", textAlign: "center" }}>
          {position}
        </PNano>
        <div
          className="bg-grey-light-scale-200 h-[6px] rounded relative"
          style={{ width: "calc(100% - 20px)" }}
        >
          <div
            className="bg-yellow h-[6px] rounded absolute top-0 left-0"
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
      {addSpace && <div className="h-2" />}
    </Fragment>
  );
};

export default ReviewsSummary;
