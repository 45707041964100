import { FunctionalComponent, h } from "preact";
import { ActiveComponent } from "../../../../website/components/state";
import { H5 } from "@tempoplatform/tpds/_dist/elements/typography";
import BackButton from "../../atoms/BackButton";
import ShareIcon from "../../atoms/ShareButton";
import { ProductData } from "../../../types/types.ts";

interface Props {
  pid: number;
  data: any;
  product: ProductData;
  setReset: any;
  resetVariantSelection: () => void;
  setActiveComponent: (c: ActiveComponent) => void;
  setCurrentPage: (value: string) => void;
}

const TopHeader: FunctionalComponent<Props> = (props: Props) => {
  const { pid, data, product, setReset, resetVariantSelection, setCurrentPage } = props;
  const resetInfoBoxes = () => {
    setReset(true);
    setCurrentPage("home");
  };

  return (
    <div style={{ paddingRight: data.video_url ? "84px" : "55px", ...styles.gridWrapper }}>
      <BackButton
        resetVariantSelection={resetVariantSelection}
        resetInfoBoxes={resetInfoBoxes}
        setActiveComponent={(component: any) => props.setActiveComponent(component)}
      />
      <H5
        isMedium
        style={{ marginRight: "auto", marginLeft: "12px", position: "relative", top: "1px" }}
      >
        {data.brand_name}
      </H5>
      <ShareIcon pid={pid} product={product} />
    </div>
  );
};

const styles = {
  gridWrapper: {
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    paddingLeft: "10px",
    borderBottom: "1px solid #f1f1f1",
  },
};

export default TopHeader;
