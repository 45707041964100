/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createRef, FunctionalComponent, h } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import { ActiveComponent } from "../../../website/components/state";
import ProductSelectSection from "./ProductSelectSection";
import UserReviews from "./reviews/UserReviews";
import { ProductData, SkuProductVariantOptionsData } from "../../types/types";
import ProductInformation from "./ProductInformation";
import ProductDetails from "./ProductDetails";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import ProductImageSlider from "./ProductImageSlider";
import ImagesAndFormAdType from "./ImagesAndFormAdType";
import TopHeader from "./subcomponents/TopHeader";
import BrandAwarenessCTA from "./subcomponents/BrandAwarenessCTA";
import { Stripe, StripeConstructorOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants/keys.tsx";
import ShoppableWrapper from "./subcomponents/ShoppableWrapper.tsx";
import BigNumber from "bignumber.js";

// TODO: consider moving this to utils
const getStripeForAccount = (stripeConnectedAccountID: string): Promise<Stripe> => {
  const stripeOptions: StripeConstructorOptions = {
    // TODO: migrate to latest version – coordinate with internal stripe-api maintainer
    apiVersion: "2020-08-27",
    stripeAccount: stripeConnectedAccountID || undefined,
  };

  return loadStripe(STRIPE_PUBLISHABLE_KEY, stripeOptions);
};

interface Props {
  pid: number;
  cid: number;
  selectedProductIndex: number;
  os: string;
  productFlag: boolean;
  data: any;
  swiperRef: any;
  currentPage: string;
  setCurrentPage: (value: string) => void;
  setActiveComponent: (c: ActiveComponent) => void;
  setSelectedProductIndex: (i: number) => void;
}

const Product: FunctionalComponent<Props> = (props: Props) => {
  const {
    cid,
    pid,
    data,
    swiperRef,
    productFlag,
    currentPage,
    setCurrentPage,
    setSelectedProductIndex,
  } = props;

  const ad_type: string = data.ad_type;
  const stripeConnectedAccountID: string | null = data.stripe_connected_account_id || null;
  const product: ProductData = data.campaign_products[pid].product;
  // @ts-ignore - this forces the price to be a BigNumber instead of (probably) a string
  if (!(typeof product.price === BigNumber)) {
    product.price = BigNumber(product.price);
  }

  const [currentSelection, setCurrentSelection] = useState<Array<SkuProductVariantOptionsData>>([]);
  const [imageUrlIndexStarts, setImageUrlIndexStarts] = useState<Array<number>>([]);
  const [initialImageIndex, setInitialImageIndex] = useState(0);
  const [selectedSku, setSelectedSku] = useState<number>(0);
  const [selectedSellerSku, setSelectedSellerSku] = useState<string>("");
  const [productVariant, setProductVariant] = useState<any>({});
  const [quantity, setQuantity] = useState(1);
  const [reset, setReset] = useState(false);
  const reviewWrapperRef = createRef();

  const variants = product.product_variants ?? [];
  const resetVariantSelection = () => {
    swiperRef.current.slideTo(0);
    updateQuantity(1);
  };

  const stripePromise: Promise<Stripe> | null = useMemo(() => {
    if (ad_type === "shoppable") {
      return getStripeForAccount(stripeConnectedAccountID);
    }
    return null;
  }, [ad_type, stripeConnectedAccountID]);

  useEffect(() => {
    // set image slider state
    const allIndices = product.skus.map(obj => obj.image_url_index);
    const uniqueIndices = allIndices.filter((v, i) => allIndices.indexOf(v) == i);
    const sortedUniqueIndices = uniqueIndices.sort((a, b) => a - b);
    setImageUrlIndexStarts(sortedUniqueIndices);

    // set product selection state
    const initialState: Array<any> = [];
    const initialProductVariant: any = {};
    for (const variant of product.product_variants) {
      initialState.push({
        product_variant_id: variant.id,
        product_variant_option_id: variant.product_variant_options[0].id,
      });
      initialProductVariant[variant.name] = variant.product_variant_options[0].name;
    }
    setProductVariant(initialProductVariant);
    setCurrentSelection(initialState);
  }, [product]);

  useEffect(() => {
    setSku(currentSelection);
  }, [currentSelection]);

  const setSku = (selectedOptions: Array<SkuProductVariantOptionsData>) => {
    const foundIndex: any =
      product?.skus?.length > 0 &&
      product?.skus?.find((pr: any) => {
        // TODO: clean this up - it's only done like this for debugging purposes
        let matchSoFar = true;
        if (selectedOptions.length !== pr.sku_product_variant_options.length) {
          matchSoFar = false;
        }
        for (const [i, selectedOption] of selectedOptions.entries()) {
          if (
            selectedOption.product_variant_id !=
            pr.sku_product_variant_options[i].product_variant_id
          ) {
            matchSoFar = false;
          }
          if (
            selectedOption.product_variant_option_id !=
            pr.sku_product_variant_options[i].product_variant_option_id
          ) {
            matchSoFar = false;
          }
        }
        if (matchSoFar) {
          setSelectedSku(pr.id);
          setSelectedSellerSku(pr.seller_sku_id);
          return pr;
        }
        return false;
      });

    setInitialImageIndex(foundIndex?.image_url_index || 0);
  };

  const setReviewWrapper = (state: boolean): void => {
    if (state) {
      reviewWrapperRef.current.style.display = "flex";
      setTimeout(() => {
        reviewWrapperRef.current.classList.add("is_open");
      }, 100);
    } else {
      reviewWrapperRef.current.classList.remove("is_open");
      setTimeout(() => {
        reviewWrapperRef.current.style.display = "none";
      }, 400);
    }
  };

  const updateQuantity = async (quantity: number) => {
    setQuantity(quantity);
  };

  const productReviews = product.product_reviews;
  const reviewScores = product.product_review_scores;
  return (
    <div style={styles.productPageWrapper}>
      {productReviews && reviewScores && (
        <div className="user-review-wrapper" ref={reviewWrapperRef}>
          <UserReviews
            reviews={productReviews}
            reviewScores={reviewScores}
            setReviewWrapper={setReviewWrapper}
          />
        </div>
      )}
      <TopHeader
        pid={pid}
        product={product}
        data={data}
        resetVariantSelection={resetVariantSelection}
        setActiveComponent={props.setActiveComponent}
        setCurrentPage={setCurrentPage}
        setReset={setReset}
      />
      <ProductImageSlider
        swiperRef={swiperRef}
        pid={pid}
        product={product}
        currentPage={currentPage}
        initialImageIndex={initialImageIndex}
        imageUrlIndexStarts={imageUrlIndexStarts}
      />
      <div style={styles.pagination} className="pagination-wrapper" />
      {ad_type !== "images_and_form" && (
        <ProductDetails
          ad_type={ad_type}
          reviewScores={reviewScores}
          product={product}
          data={data}
          quantity={quantity}
        />
      )}
      {ad_type !== "images_and_form" && variants.length ? (
        <div style={{ padding: "0 16px 16px 16px" }}>
          <ProductSelectSection
            variants={variants}
            reset={reset}
            data={data}
            ad_type={ad_type}
            product={product}
            setReset={setReset}
            setProductVariant={(key: any, value: any) => {
              setProductVariant({ ...productVariant, [key]: value });
            }}
            productVariant={productVariant}
            currentSelection={currentSelection}
            setCurrentSelection={setCurrentSelection}
            resetVariantSelection={resetVariantSelection}
            selectedQuantity={quantity}
            handleQuantitySelectChange={(e: any) => updateQuantity(e.target.value)}
          />
        </div>
      ) : null}
      {ad_type === "brand_awareness" && data.cta && data.cta !== "" && (
        <BrandAwarenessCTA data={data} product={product} />
      )}
      {ad_type !== "images_and_form" && (
        <ProductInformation
          // reset={reset}
          // setReset={setReset}
          setReviewWrapper={setReviewWrapper}
          productFlag={productFlag}
          product={product}
          // isAnyVariant={Boolean(variants.length)}
        />
      )}
      {ad_type === "images_and_form" && (
        <ImagesAndFormAdType
          productFlag={productFlag}
          data={data}
          product={product}
          setSelectedProductIndex={setSelectedProductIndex}
          resetVariantSelection={resetVariantSelection}
          cid={cid}
        />
      )}
      {ad_type === "shoppable" && stripePromise !== null && (
        <ShoppableWrapper
          stripePromise={stripePromise}
          data={data}
          product={product}
          variants={variants}
          productVariant={productVariant}
          sellerSku={selectedSellerSku}
          stripeConnectedAccountID={stripeConnectedAccountID}
          currentSelection={currentSelection}
          pid={pid}
          cid={cid}
          quantity={quantity}
          sku={selectedSku}
        />
      )}
    </div>
  );
};

const styles = {
  pagination: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  productPageWrapper: {
    flexDirection: "row",
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 20,
    overflowY: "scroll",
    paddingBottom: "40px",
  },
};

export default Product;
