import { FunctionalComponent, h } from "preact";
import Router from "preact-router";
import theme from "../src/theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Home from "./components/Home";
import Campaign from "../src/components/campaign/Campaign";
import PreviewCampaign from "../src/components/preview/PreviewCampaign";
import ThankYou from "../src/components/ThankYou";
import CampaignMobileWrapper from "../src/components/campaign/CampaignMobileWrapper";
import Colors from "@tempoplatform/tpds/_dist/system/Colors";
//import GlobalStyles from "@tempoplatform/tpds/_dist/system/GlobalStyles";



const App: FunctionalComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <Colors />
      <Router>
        <PreviewCampaign path="/preview/product/:pid" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid/:os?" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid/product/:pid" />
        {/*@ts-ignore*/}
        <Campaign path="/campaign/:cid/product/:pid/:os?" />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid/:os?" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid/product/:pid" isInterstitial={true} />
        {/*@ts-ignore*/}
        <Campaign path="/interstitial/:cid/product/:pid/:os?" isInterstitial={true} />
        {/*@ts-ignore*/}
        <CampaignMobileWrapper path="/campaignmobile/:cid" />
        {/*@ts-ignore*/}
        <CampaignMobileWrapper path="/campaignmobile/:cid/product/:pid" />
        <ThankYou path="/thank-you" />
        <Home default />
      </Router>
    </ThemeProvider>
  );
};

export default App;
