import { Fragment, FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box } from "@mui/material";
import { H6, P, PSmall } from "@tempoplatform/tpds/_dist/elements/typography";
import { Separator } from "@tempoplatform/tpds/_dist/elements/layout";
import ShareIcon from "../../atoms/ShareIcon";
import validator from "validator";
import { Button } from "@tempoplatform/tpds/_dist/elements/buttons";
import clsx from "clsx";
import { ProductData } from "../../../types/types.ts";

const drawerBleeding = 56;

const pullerStyle = {
  width: 30,
  height: 6,
  backgroundColor: "#444",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
};

interface Props {
  data: any;
  product: ProductData;
}

const BrandAwarenessCTA: FunctionalComponent<Props> = (props: Props) => {
  const { data, product } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const toggleDrawer = (newOpen: boolean) => () => {
    setDialogOpen(newOpen);
  };
  const submitForm = () => {
    console.log("submitForm");
    if (validator.isEmail(emailAddress)) {
      setEmailInvalid(false);
    } else {
      setEmailInvalid(true);
    }
    setSubmittedOnce(true);
  };

  const ctaLabel = data.cta && data.cta !== "" ? data.cta : "CTA label";
  const submissionSuccess = submittedOnce && !emailInvalid;

  const classInitialButtonsBox = clsx(
    `fixed left-[4px]`,
    `grid grid-cols-2 gap-2`,
    `bg-white px-4 pt-3 pb-6 rounded-lg`,
    dialogOpen ? `bottom-[100px]` : `bottom-[4px]`,
  );

  const inputClass = clsx(
    `w-full mb-2 py-2 px-4`,
    `rounded-lg border-none outline-none`,
    `bg-grey-light-scale-300`,
  );

  return (
    <div className="brand-awareness-cta w-full fixed bottom-0 left-0 z-50">
      <div
        className={classInitialButtonsBox}
        style={{ boxShadow: "2px 16px 29px 9px rgba(0, 0, 0, 0.5)", width: "calc(100% - 8px)" }}
      >
        <Button variant="info" isLarge onClick={() => setDialogOpen(true)}>
          {ctaLabel}
        </Button>
        <Button isLarge>
          <ShareIcon style={{ height: "18px", width: "19px", position: "relative", top: "-2px" }} />{" "}
          Share
        </Button>
      </div>
      <SwipeableDrawer
        //container={window.document.body}
        anchor="bottom"
        open={dialogOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        className="brand-awareness-cta-drawer"
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="p-8 rounded-lg">
          <Box sx={pullerStyle} />
          <H6 isMedium className="leading-none mb-1">
            {product.name}
          </H6>
          <Separator />
          {!submissionSuccess && (
            <P isMedium className="mb-1 mt-2 select-none leading-normal">
              Enter your email to learn more about this product!
            </P>
          )}
          {submissionSuccess && (
            <P isMedium className="mb-1 mt-2 select-none leading-normal">
              Thank you for your interest in this product!{" "}
              <span className="font-normal">We will e-mail you with more information. 💌</span>
            </P>
          )}

          {!submissionSuccess && (
            <Fragment>
              <P isMedium className="leading-none mb-2 mt-4 text-tertiary">
                Your email
              </P>
              <input
                className={inputClass}
                type="text"
                placeholder="your.email@host.com"
                value={emailAddress}
                onChange={(e: any) => setEmailAddress(e.target.value)}
              />
              <Button variant="info" isLarge style={{ width: "100%" }} onClick={() => submitForm()}>
                {ctaLabel}
              </Button>
              {submittedOnce && emailInvalid && (
                <PSmall isMedium className="text-orange mt-2">
                  Please provide a valid email address
                </PSmall>
              )}
            </Fragment>
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default BrandAwarenessCTA;
