import { Fragment, FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { toCreatePaymentIntent } from "../../../api/api";
import { logEvent } from "../../../logging/eventProducer";
import ShippingModeSelect from "./ShippingModeSelect";
import { Stripe, StripeAddressElementChangeEvent, StripeElements } from "@stripe/stripe-js";
import { AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import GooglePayButton from "@google-pay/button-react";
import { MOCK_PAYMENT_REQUEST_DATA } from "../../../constants/objects.tsx";
import { ProductData } from "../../../types/types.ts";
import BigNumber from "bignumber.js";
import { ShippingAddress } from "../../../utils/stripe.tsx";

// TODO: Consider moving this to utils
function usePollingForGPayError(interval = 100): boolean | null {
  const [gPayError, setGPayError] = useState<boolean | null>(null);

  useEffect(() => {
    const poll = setInterval(() => {
      // If not declared, return early
      if (!("gPayError" in window)) {
        return;
      }

      // We coerce the value to a boolean, but it should always be a boolean.
      const newGPayErrorState = !!window.gPayError;
      setGPayError(newGPayErrorState);

      // The value will only be set once by our SDK, so we can stop polling.
      clearInterval(poll);
    }, interval);

    return () => clearInterval(poll);
  }, [gPayError, interval]);

  return gPayError;
}

interface Props {
  product: ProductData;
  variants: any;
  data: any;
  pid: number;
  cid: number;
  sku: number;
  sellerSku: string;
  quantity: number;
  productVariant: any;
  currentSelection: any;
  stripeConnectedAccountID: string;
}

const buttonContainerStyle = {
  position: "fixed",
  bottom: 0,
  background: "white",
  zIndex: 999,
  left: 0,
  padding: "5px",
};

const ShoppableAndroid: FunctionalComponent<Props> = ({
  product,
  cid,
  pid,
  sku,
  sellerSku,
  quantity,
  productVariant,
  currentSelection,
  stripeConnectedAccountID,
}) => {
  const [selectedShippingMode, setSelectedShippingMode] = useState<any>(null);
  const [shippingAddress, setShippingAddress] = useState<ShippingAddress | null>(null);

  // These are both null until the Promises resolve.
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  // poll every 100ms for the state provided by the Android SDK
  const gPayError: boolean | null = usePollingForGPayError(100);

  const createRequestBody = () => {
    const variant_info_arr: string[] = [];
    const variant_ids_arr: string[] = [];
    for (const key in productVariant) {
      variant_info_arr.push(`${key}:${productVariant[key]}`);
    }
    for (const id in currentSelection) {
      variant_ids_arr.push(
        `${currentSelection[id].product_variant_id}:${currentSelection[id].product_variant_option_id}`,
      );
    }

    const shippingPrice = selectedShippingMode ? selectedShippingMode.amount : 0;

    const requestBody: any = {
      currency: "USD",
      price: product.price.toString(),
      quantity,
      metadata: {
        sku_id: sku,
        product_name: product.name,
        product_id: pid,
        campaign_id: cid,
        seller_sku_id: sellerSku,
        variant_info: variant_info_arr.join(","),
        variant_ids: variant_ids_arr.join(","),
        quantity,
        shipping_option_id: selectedShippingMode ? selectedShippingMode.id : null,
        shipping_option_name: selectedShippingMode ? selectedShippingMode.display_name : null,
        shipping_price: selectedShippingMode
          ? BigNumber(selectedShippingMode.amount).toString()
          : "0",
      },
      shipping_price: BigNumber(shippingPrice).toString(),
    };
    if (stripeConnectedAccountID) {
      requestBody.stripe_account = stripeConnectedAccountID;
    }
    if (shippingAddress) {
      requestBody["shipping"] = shippingAddress;
    } // If no shipping address, probably shouldn't proceed with the payment.

    return requestBody;
  };

  const createPaymentIntent = async (): Promise<string | null> => {
    const requestBody = createRequestBody();
    try {
      const response = await toCreatePaymentIntent(requestBody);
      const data = await response.json();
      if (data.error) {
        console.error(`Error creating payment intent: ${data.error}`);
        return;
      }

      logEvent("PAYMENT_INTENT_SUCCESS");

      return data.client_secret;
    } catch (err: any) {
      console.error(`Uncaught error in createPaymentIntent(): ${err}`);
      return null;
    }
  };

  const onAddressComplete = async (shippingDetailsEvent: StripeAddressElementChangeEvent) => {
    setShippingAddress({
      address: {
        city: shippingDetailsEvent.value.address.city,
        country: shippingDetailsEvent.value.address.country,
        line1: shippingDetailsEvent.value.address.line1,
        line2: shippingDetailsEvent.value.address.line2,
        postal_code: shippingDetailsEvent.value.address.postal_code,
        state: shippingDetailsEvent.value.address.state,
      },
      name: shippingDetailsEvent.value.name,
    });
  };

  const onGooglePayButtonClick = async (event: Event) => {
    // We do preventDefault here because we want to handle the Google Pay flow ourselves.
    // This is because we need to call our Android SDK to launch Google Pay and handle the payment.
    event.preventDefault();

    logEvent(`GOOGLE_PAY_CLICKED_PRODUCT_${pid}`);

    // This creates the paymentIntent in the Google Pay flow
    const clientSecret: string | null = await createPaymentIntent();
    if (!clientSecret) {
      window.alert("Error creating payment intent");
      return;
    }

    // @ts-ignore
    if (typeof Android === "undefined" || Android === null) {
      alert("Not viewing in webview");
      return;
    }

    // @ts-ignore
    if (Android.launchGooglePayWithDetails) {
      // This is the preferred method, which only exists in newer SDK versions.
      // @ts-ignore
      Android.launchGooglePayWithDetails(clientSecret, cid, pid);
      // @ts-ignore
    } else if (Android.launchGooglePay) {
      // We fall back to this legacy method, which only exists in older SDK versions.
      // @ts-ignore
      Android.launchGooglePay(clientSecret);
    } else {
      logEvent("GOOGLE_PAY_METHOD_UNAVAILABLE");
      window.alert("launchGooglePay method not available");
      return;
    }

    if (gPayError) {
      logEvent("GOOGLE_PAY_ERROR");
      window.alert("Google Wallet is either not installed, or has no valid cards.");
      return;
    }
  };

  useEffect(() => {
    const pShippingOptions = product["campaign_product_shipping_options"];
    if (pShippingOptions && pShippingOptions.length > 0) {
      setSelectedShippingMode(pShippingOptions[0].shipping_option);
    }
  }, [product]);

  // TODO: consider adding a loading spinner here
  if (!stripe || !elements) {
    return null;
  }

  // If the Android webview is unable to use Google Pay, we don't want to show the payment form.
  if (gPayError) {
    return null;
  }

  return (
    <Fragment>
      <ShippingModeSelect
        selectedShippingMode={selectedShippingMode}
        shippingModes={product["campaign_product_shipping_options"]}
        setSelectedShippingMode={setSelectedShippingMode}
      />
      <div className="w-full border-t border-[#F1F1F1] p-[16px]">
        <div className="flex flex-col gap-y-2 w-full mb-[20px]">
          <AddressElement
            options={{
              mode: "shipping",
              // TODO: Get this from the data object returned by rest-ads-api
              allowedCountries: ["US"],
            }}
            onChange={(event: StripeAddressElementChangeEvent) => {
              if (event.complete) {
                onAddressComplete(event);
              }
            }}
          />
          <div style={buttonContainerStyle}>
            <div
              style={
                // If there is no shipping address set, disable the Google Pay button.
                // TODO: Consider whether we want to logEvent on click anyway to track user behaviour.
                !shippingAddress ? { opacity: 0.5, pointerEvents: "none" } : {}
              }
            >
              <GooglePayButton
                style={styles.paymentButton}
                buttonType="order"
                buttonSizeMode="fill"
                environment="TEST"
                paymentRequest={MOCK_PAYMENT_REQUEST_DATA}
                onClick={onGooglePayButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styles = {
  paymentButton: {
    width: "100%",
    height: "47.5px",
    border: "0px",
    zIndex: 1,
  },
};

export default ShoppableAndroid;
