import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ShoppableNonAndroid from "./ShoppableNonAndroid.tsx";
import { ProductData } from "../../../types/types.ts";
import ShoppableAndroid from "./ShoppableAndroid.tsx";

interface Props {
  stripePromise: Promise<Stripe>;
  product: ProductData;
  variants: any;
  data: any;
  pid: number;
  cid: number;
  sku: number;
  sellerSku: string;
  quantity: number;
  productVariant: any;
  currentSelection: any;
  stripeConnectedAccountID: string;
}

const ShoppableWrapper: FunctionalComponent<Props> = (props: Props) => {
  const {
    stripePromise,
    product,
    variants,
    data,
    pid,
    cid,
    sku,
    sellerSku,
    quantity,
    productVariant,
    currentSelection,
    stripeConnectedAccountID,
  } = props;

  // prevStripe lags one render behind stripePromise
  const [prevStripe, setPrevStripe] = useState<Promise<Stripe>>(stripePromise);

  const isAndroidWebView: boolean = navigator.userAgent.includes("Tempo-Android-SDK");

  useEffect(() => {
    console.log("useEffect [stripePromise, prevStripe]", stripePromise, prevStripe);
    setPrevStripe(stripePromise);
  }, [stripePromise, prevStripe]);

  // This forces an unmount and remount of <Elements /> when stripePromise changes.
  // See: https://github.com/stripe/react-stripe-js/issues/107#issuecomment-1470742940
  if (prevStripe !== stripePromise) return null;

  const stripeElementsConfig: StripeElementsOptions = {
    mode: "payment",
    amount: product.price.times(quantity).times(100).integerValue().toNumber(),
    currency: "usd",
  };

  return (
    <Elements stripe={stripePromise} options={stripeElementsConfig} key={stripeConnectedAccountID}>
      {isAndroidWebView ? (
        <ShoppableAndroid
          data={data}
          product={product}
          variants={variants}
          productVariant={productVariant}
          sellerSku={sellerSku}
          stripeConnectedAccountID={stripeConnectedAccountID}
          currentSelection={currentSelection}
          pid={pid}
          cid={cid}
          quantity={quantity}
          sku={sku}
        />
      ) : (
        <ShoppableNonAndroid
          data={data}
          product={product}
          variants={variants}
          productVariant={productVariant}
          sellerSku={sellerSku}
          stripeConnectedAccountID={stripeConnectedAccountID}
          currentSelection={currentSelection}
          pid={pid}
          cid={cid}
          quantity={quantity}
          sku={sku}
        />
      )}
    </Elements>
  );
};

export default ShoppableWrapper;
