import { Fragment, FunctionalComponent, h } from "preact";
import ColorSelector from "./subcomponents/ColorSelector";
import OptionSelector from "./subcomponents/OptionSelector";
import { ProductVariantOptionsData } from "../../types/types";

interface Props {
  id: any;
  name: string;
  options: Array<ProductVariantOptionsData>;
  selectedIndex: number;
  setSelectedIndex: any;
  setProductVariant: (key: any, value: any) => void;
  productVariant: any;
  resetVariantSelection: () => void;
}

const ProductSelect: FunctionalComponent<Props> = (props: Props) => {
  const { name, options, selectedIndex, setSelectedIndex, setProductVariant } = props;

  return (
    <Fragment>
      {name.toLowerCase() === "color" ? (
        <ColorSelector
          name={name}
          options={options}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setProductVariant={setProductVariant}
        />
      ) : (
        <OptionSelector
          name={name}
          options={options}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setProductVariant={setProductVariant}
        />
      )}
    </Fragment>
  );
};

export default ProductSelect;
