import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { PSmall } from "@tempoplatform/tpds/_dist/elements/typography";
import { ProductData, ProductVariantsData, SkuProductVariantOptionsData } from "../../types/types";
import ProductSelect from "./ProductSelect";
import QuantitySelect from "./subcomponents/QuantitySelect";
import OrderSummary from "./OrderSummary";

interface Props {
  variants: Array<ProductVariantsData>;
  reset: boolean;
  setReset: any;
  product: ProductData;
  data: any;
  setProductVariant: (key: any, value: any) => void;
  productVariant: any;
  currentSelection: Array<SkuProductVariantOptionsData>;
  setCurrentSelection: (value: Array<SkuProductVariantOptionsData>) => void;
  resetVariantSelection: () => void;
  // quantity selector
  selectedQuantity: number;
  handleQuantitySelectChange: any;
  ad_type: string;
}

const ProductSelectSection: FunctionalComponent<Props> = (props: Props) => {
  const {
    setProductVariant,
    productVariant,
    setCurrentSelection,
    resetVariantSelection,
    variants,
    data,
    product,
    ad_type,
  } = props;
  // quantity selector
  const { selectedQuantity, handleQuantitySelectChange } = props;

  const tempSelection: Array<SkuProductVariantOptionsData> = [];
  const initialState = () => {
    const stateList: Array<any> = [];
    for (const variant of props.variants) {
      stateList.push(variant.product_variant_options[0].id);
    }
    return stateList;
  };
  const [indexState, setIndexState] = useState(initialState());
  const updateIndexState = (index: any, value: any) => {
    setIndexState(prev => prev.map((number, i) => (i == index ? value : number)));
  };

  const updateCurrentSelection = (index: any) => {
    tempSelection.length = 0;
    for (const [i, variant] of props.variants.entries()) {
      tempSelection.push({
        product_variant_id: variant.id,
        product_variant_option_id: index[i],
      });
    }
    setCurrentSelection(tempSelection);
  };

  useEffect(() => {
    updateCurrentSelection(indexState);
  }, [indexState]);

  useEffect(() => {
    setIndexState(initialState());
  }, [variants]);

  return (
    <div className="flex flex-col gap-y-2 mb-1">
      {props.variants.length > 1 &&
        props.variants.map((variant, i) => (
          <ProductSelect
            key={i}
            id={variant.id}
            name={variant.name}
            options={variant.product_variant_options}
            selectedIndex={indexState[i]}
            setSelectedIndex={(index: any) => updateIndexState(i, index)}
            setProductVariant={setProductVariant}
            productVariant={productVariant}
            resetVariantSelection={resetVariantSelection}
          />
        ))}
      {ad_type !== "brand_awareness" && (
        <div>
          <PSmall isMedium className="leading-none capitalize !mb-1">
            Amount
          </PSmall>
          <div className="flex gap-x-2 gap-y-2 items-center">
            <QuantitySelect
              maxQuantity={10}
              selectedQuantity={selectedQuantity}
              handleSelectChange={handleQuantitySelectChange}
            />
            <div className="flex flex-col gap-y-1">
              <PSmall isMedium className="leading-none capitalize">
                {data.brand_name.toLowerCase()}
                &nbsp;&bull;&nbsp;
                {product.name.toLowerCase()}
              </PSmall>
              <div className="flex gap-x-1">
                {props.variants.map((variant, i) => (
                  <OrderSummary
                    key={i + 1}
                    addBullet={i < props.variants.length - 1}
                    name={variant.name}
                    options={variant.product_variant_options}
                    selectedIndex={indexState[i]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductSelectSection;
