import { FunctionalComponent, h } from "preact";
import ShareIcon from "./ShareIcon";
import { logEvent } from "../../logging/eventProducer";
import { ProductData } from "../../types/types.ts";

interface Props {
  product: ProductData;
  pid: number;
}

const ShareButton: FunctionalComponent<Props> = (props: Props) => {
  const { product, pid } = props;
  return (
    <ShareIcon
      style={{ position: "relative", top: "-1px" }}
      onClick={() => {
        logEvent(`SHARE_PRODUCT_${pid}`);
        if (navigator.share) {
          navigator.share({
            title: product.name,
            text: product.url,
            url: product.url,
          });
          // @ts-ignore
        } else if (typeof Android !== "undefined" && Android !== null) {
          // @ts-ignore
          Android.nativeShare(product.name, product.url);
        }
      }}
    />
  );
};

export default ShareButton;
