// This is used to instantiate the Google Pay button, but we overload the onClick event to call a native Android method.
export const MOCK_PAYMENT_REQUEST_DATA: google.payments.api.PaymentDataRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  emailRequired: true,
  shippingAddressRequired: true,
  shippingAddressParameters: {
    allowedCountryCodes: ["US"],
  },
  shippingOptionRequired: true,
  merchantInfo: {
    merchantId: "BCR2DN4TYTCIKLY",
    merchantName: "Tempo Platform, Inc",
  },
  allowedPaymentMethods: [
    {
      type: "CARD",
      parameters: {
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        allowedCardNetworks: ["MASTERCARD", "VISA"],
      },
      tokenizationSpecification: {
        type: "PAYMENT_GATEWAY",
        parameters: {
          gateway: "example",
          gatewayMerchantId: "exampleGatewayMerchantId",
        },
      },
    },
  ],
  transactionInfo: {
    totalPriceStatus: "FINAL",
    totalPriceLabel: "Total",
    totalPrice: "100.00",
    currencyCode: "USD",
    countryCode: "US",
  },
};
